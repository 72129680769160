:root {
  --primary: #0602fc;
  /* --primary: #d2a33b; */
  --secondary: #a2bbf1;
  /* --secondary: #ffe7b2; */
  --blue: #1e90ff;
  --white: #ffffff;
  --lightgray: #f1f1f1;
  --darkgray: #5c5c5c;
  --mildgray: #dbd6d6;
  --black: #0d0d0d;
}

/* icon css start */

@font-face {
  font-family: 'hungry-top';
  src: url('./font/hungry-top.eot?81069497');
  src: url('./font/hungry-top.eot?81069497#iefix') format('embedded-opentype'), url('./font/hungry-top.woff?81069497') format('woff'), url('./font/hungry-top.ttf?81069497') format('truetype'), url('./font/hungry-top.svg?81069497#hungry-top') format('svg');
  font-weight: normal;
  font-style: normal;
}

.demo-icon {
  font-family: 'hungry-top';
  font-style: normal;
  font-weight: normal;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  line-height: 1em;
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}


/* Chrome, Safari, Edge, Opera */

input::-webkit-outer-spin-button, input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */

input[type=number] {
  -moz-appearance: textfield;
}

/* icon css end */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box !important;
  text-decoration: none !important;
}

.cursor-pointer {
  cursor: pointer;
}
.primary-badge{
  background-color: #f5f5f5;
  color: #b3b5b7;
}
.success-badge{
  background-color: #e6f8ee;
  color: #32c975;
}
.danger-badge{
  background-color: #fbd7ce;
  color: #ffa54e;
}
.custom-badge{
  background-color: #030000;
  color: #fff;
}
.font-12 {
  font-size: 12px;
}

.font-10 {
  font-size: 10px;
}

.font-15 {
  font-size: 15px;
}

.font-20 {
  font-size: 20px;
}
.font-25 {
  font-size: 25px;
}
.font-30 {
  font-size: 30px;
}
.tag-icon{
  width: 25px;
  height: 25px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.user-table-row-link {
  display: contents;
  color: #0d0d0d;
}

.text-color-primary {
  color: var(--primary);
}
.text-white{
  color: #fff !important;
}
.text-primary{
  color: blue !important;
}
.text-black{
  color: #000 !important;
}

.logo {
  height: 52px;
  width: 84px;
  object-fit: cover;
  /* margin-right: 100px; */
}
.logo1 {
  height: 52px;
  width: 84px;
  object-fit: cover;
  background-color: #ffff;
  border-radius: 50%;
  /* margin-right: 100px; */
}
.search-input{
  /* margin-top: 8px; */
  font-size: 17px;
  background-color: #e1e1e1;
  border: 1px solid #c3c3c3;
  /* width: 100%; */
  border-radius: 4px;
  padding: 2px 12px;
}
.userName {
  margin-right: 10px;
}

.MuiSvgIcon-root {
  width: 19px !important;
}

.error-msg {
  color: red;
  font-size: 12px;
  margin: 0;
  text-align: right;
  height: 19px;
}

.admin-sidebar {
  background-color: darkgrey;
  height: 100vh;
}

.user-manage {
  display: flex;
  justify-content: center;
  /* align-items: center; */
}

/* Rounded sliders */

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.otp_input_item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.item {
  width: 20%;
  border: none;
  outline: none;
  border-bottom: 2px solid #aaa;
  text-align: center;
  font-size: xxx-large;
  font-size: -webkit-xxx-large;
}

.d_center {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* new design */

.bg-btn-color {
  background-color: #cbf3ee;
  color: var(--black);
}

.bg-btn-lightgray {
  background-color: var(--lightgray);
}

.auth-bg-image {
  background-color: #ffecc4;
  height: 100vh;
  overflow: hidden;
}

.auth-bg-image img {
  transform: scale(1.5);
  opacity: 0.15;
}

.auth-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.logo-auth {
  width: 200px;
}

.user-login {
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 6px;
  opacity: 1;
  width: 429px;
  /* height: 384px; */
  padding: 24px 40px;
}

.user-login-btn {
  width: 100%;
  height: 50px;
  background: #f1f1f1 0% 0% no-repeat padding-box;
  border-radius: 4px;
  border: none;
  opacity: 1;
  color: darkgrey;
  outline: none !important;
}

.user-input {
  width: 100%;
  height: 50px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #e4dede;
  border-radius: 4px;
  padding: 0 24px;
  opacity: 1;
  outline: none;
}

.re-otp {
  display: flex;
  margin-top: 14px;
  font-size: 16px;
  font: 16px;
  color: #0d0d0d;
  justify-content: space-between;
}

.nav-section {
  /* border-bottom: 1px solid #f5f2f2; */
  background-color: #214162;
  /* background-color: #fff; */
  box-shadow: 0 0 10px rgba(0, 0, 0.3, 0.3);
  padding: 0 20px 0 20px;
  z-index: 1;
}
.nav-btn{
  background: transparent;
  color: #000;
  border: 1px #000 solid;
  min-width: 4px !important;
  max-width: 4px !important;
  min-height: 4px !important;
  max-height: 4px !important;
  border-radius: 50px;
}

/* .nav-search {
  background: #f1f1f1;
  border: none;
  border-radius: 6px;
  width: 19rem;
  padding: 5px 15px 5px 33px;
  font-size: 14px;
}
.nav-search-icon {
    position: absolute;
    color: #4d4d4d;
    top: 9px;
    background: #f1f1f1;
    right: 13px;
    cursor: pointer;
} */

.current-user {
  display: flex;
  flex-direction: column;
}

.current-user-count {
  font-size: 16px;
  line-height: 1;
  color: var(--darkgray);
  font-weight: 600;
}

.user-profile {
  object-fit: cover;
  height: 40px;
  width: 40px;
  background-color: darkgray;
  border-radius: 50px;
  /* border: 1px solid var(--primary); */
  margin-right: 4px;
}

.table-img {
  object-fit: cover;
  height: 40px !important;
  width: 40px;
}

.menu-item {
  color: #fff !important;
  font-size: 15px;
  color: var(--darkgray);
  list-style-type: none;
  border-radius: 5px;
  margin: 7px;
  letter-spacing: 0;
  padding: 7px 14px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.menu-item svg {
  margin-right: 8px;
}

.menu-item:hover {
  background-color: #d6dbf2;
  color: #000 !important;
}
.menu-item.is-active {
  background-color: #fff;
  /* background-color: blue; */
  color: #000 !important;
}

.p-t-60 {
  padding-top: 60px;
}

.left-panel {
  background-color: #15283c;
  position: fixed;
  width: 210px;
  max-width: 210px;
  /* height: calc(200vh - 60px) !important; */
  /* height: auto !important; */
  padding-top: 15px;
  padding-bottom: 3% !important;
  overflow-y: auto;
  color: #fff;
  transition: all 0.3s;
  position: relative;
  z-index: 11;
  box-shadow: 0 0 3px 0px rgba(0, 0, 0, 0.4);
  float: left;
  width: 100%;
  /* background-image: url(assimages/layout_img/pattern_h.png); */
  background-image: url(./img/pattern_h.png);
  position: fixed;
  height: 100%;
  overflow: auto;
}
.hungry-logo {
  margin-left: 2;
  max-width: fit-content;
  overflow-y: auto;
  background-color: #fff;
  color: #fff;
  transition: all 0.3s;
  position: relative;
  z-index: 11;
  box-shadow: 0 0 3px 0px rgba(0, 0, 0, 0.4);
  float: left;
  width: 100%;
  height: 100%;
  overflow: auto;
  align-items: center !important;
  border-radius: 50%;
}
.nav-user {
  margin-left: 2;
  max-width: fit-content;
  overflow-y: auto;
  background-color: #fff;
  color: #f90101;
  transition: all 0.3s;
  position: relative;
  /* z-index: 11; */
  box-shadow: 0 0 3px 0px rgba(0, 0, 0, 0.4);
  float: left;
  width: 100%;
  height: 100%;
  overflow: auto;
  align-items: center !important;
  border-radius: 50%;
}
.side-drower {
  display: none;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}


/* ::-webkit-scrollbar{
  width: 12px;
} */

/* Left panel scroll bar start */

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #ffffff;
}

::-webkit-scrollbar {
  width: 6px;
  background-color: #F5F5F5;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
  background-color: var(--mildgray);
}

/* Left panel scroll bar end */

.right-panel {
  width: calc(100% - 210px);
  margin-left: 210px;
  padding: 0 20px;
}

.sticky {
  position: fixed;
  width: 100%;
  top: 0;
  height: 60px;
  z-index: 1;
}

.notifi-box {
  background-color: #ffffff;
  color: #8e959b;
  width: 100%;
  height: 87px;
  border-radius: 10px;
  padding: 14px;
  overflow: auto;
  /* box-shadow: 0px 5px 10px #93939329; */
}

.notifi-box:not(:last-child) {
  margin-right: 20px;
}

/*Material table-tab css */

.MuiTabs-flexContainer {
  display: inherit !important;
}

.MuiAppBar-colorDefault {
  display: contents !important;
}

.MuiTab-textColorPrimary.Mui-selected {
  color: rgb(0, 47, 255) !important;
}

* {
  outline: none !important;
}

.MuiTab-root {
  text-transform: inherit !important;
  font-family: inherit !important;
}

.MuiTableCell-root {
  border-bottom: 1px solid #f1f1f1 !important;
}

.MuiBox-root {
  padding: 20px 0 !important;
}

.PrivateTabIndicator-root-2 {
  bottom: 6px !important;
}

.MuiTabs-indicator {
  background: var(--primary) !important;
  height: 3px !important;
}

table {
  border: 1px solid #f1f1f1 !important;
  color: var(--darkgray) !important;
}

thead th {
  background-color: #ccd8f2;
  padding: 11px !important;
  color: #000;
}

.MuiTableCell-sizeSmall {
  padding: 11px 14px !important;
}

.MuiPaper-elevation1 {
  box-shadow: none !important;
}

.icon-container {
  width: 50px;
  height: 50px;
  border-radius: 50px;
  display: grid;
  place-items: center;
  color: #ffffff;

}

.title {
  font-size: 23px;
  color: var(--darkgray);
}

.sub-title {
  font-size: 12px;
  color: var(--darkgray);

}

.icon-1 {
  /* background-color: #d539db; */
  background-color: #f7f7f7;
  color: blue;
}

.icon-2 {
  /* background-color: #18a890; */
  background-color: #f7f7f7;
  color: blue;
}

.icon-3 {
  /* background-color: #ff5163; */
  background-color: #f7f7f7;
  color: blue;
}

.icon-4 {
  /* background-color: #5d92fc; */
  background-color: #f7f7f7;
  color: blue;
}

.outlet-icon-1 {
  /* background-color: #8645FF; */
  background-color: #f7f7f7;
  color: blue;
}

.outlet-icon-2 {
  /* background-color: #ef183d; */
  background-color: #f7f7f7;
  color: blue;
}

.icon-5 {
  /* background-color: #20c477; */
  background-color: #f7f7f7;
  color: blue;
}

.icon-6 {
  /* background-color: #e66421; */
  background-color: #f7f7f7;
  color: blue;
}

.icon-7 {
  /* background-color: #FCDF5D; */
  background-color: #f7f7f7;
  color: blue;
}

.is-active-btn {
  background-color: var(--secondary);
  color: #000;
  padding: 3px 21px;
  border: 1 var(--primary) !important;
}

.icon-delete {
  color: red;
}

/* //////////////////User table Switch start */

.switch {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 14px;
  margin: 4px;
  /* pointer-events: none; */
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /* border: 1px solid #47BC4B;   */
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: '';
  height: 7px;
  width: 7px;
  left: 4px;
  bottom: 3px;
  background-color: #fc573b;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked+.slider {
  border: 1px solid green;
  background-color: #ffffff;
}

input:focus+.slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked+.slider:before {
  -webkit-transform: translateX(16px);
  -ms-transform: translateX(16px);
  transform: translateX(16px);
  background-color: #47bc4b;
}

/* Rounded sliders */

.slider.round {
  border-radius: 34px;
  border: 1px solid #fc573b;
}

.slider.round:before {
  border-radius: 50%;
}

.user-table-auction-item {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* User table switch End */

.nav-drop {
  box-shadow: 3px 3px 5px #aaa;
}

.dropdown-item:hover {
  color: #d2a33b;
  text-decoration: none;
  background-color: #ffe7b2;
}

.nav-item {
  display: flex;
}

.btn:focus {
  outline: 0;
  box-shadow: none !important;
}

.add-user-container {
  border: 1px solid var(--mildgray);
}

.user-details-profile {
  width: 6rem;
  height: 6rem;
  border-radius: 50%;
  background-color: var(--lightgray);
}

.user-details-img {
  width: 6rem;
  height: 6rem;
  border-radius: 50%;
  object-fit: cover;
}

.user-details-header {
  display: flex;
}

.user-details-ratings {
  display: flex;
  justify-content: center;
}

.ratings {
  font-size: 2rem;
  color: var(--primary);
  border: 2px solid var(--primary);
  width: 6rem;
  height: 6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.MuiSwitch-colorSecondary.Mui-checked {
  color: #3ea564 !important;
}

.MuiSwitch-track {
  border: 1px solid red !important;
  background-color: transparent !important;
}

.MuiSwitch-colorSecondary.Mui-checked+.MuiSwitch-track {
  background-color: transparent !important;
  border: 1px solid green !important;
}

.MuiSwitch-thumb {
  width: 16px !important;
  height: 16px !important;
}

.MuiSwitch-root {
  height: 39px !important;
}

.MuiSwitch-switchBase {
  top: 2px !important;
}

.MuiSwitch-switchBase {
  color: #e00d0d !important;
}

.breadcrumb {
  background-color: transparent !important;
}

.breadcrumb a {
  color: var(--darkgray) !important;
}

.add-category-sticky {
  position: sticky;
  top: 68px;
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 9999; /* Ensure it's above all other content */
}

.without_ampm::-webkit-datetime-edit-ampm-field {
  display: none;
}

input[type=time]::-webkit-clear-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  -ms-appearance: none;
  appearance: none;
  margin: -10px;
}

.rc-time-picker {
  width: 100%;
}

.rc-time-picker-input {
  height: 37px !important;
  font-size: 15px !important;
}

/* /////// */

.description {
  font-size: 12px;
  color: #989898;
  overflow: hidden;
  height: 17px;
  margin: 0;
}

/* Select box design Start---------------- */

.select-box {
  position: relative;
  display: flex;
  width: 100%;
  flex-direction: column;
}

.select-box .options-container {
  background: #ffffff;
  color: #757575;
  max-height: 0;
  width: 100%;
  border: 1px solid #cccccc;
  opacity: 0;
  transition: all 0.4s;
  /* border-radius: 0px; */
  overflow: hidden;
  order: 1;
  position: absolute;
  z-index: 100;
}

.selected {
  background: #ffffff;
  border: 1px solid #cccccc;
  border-radius: 4px;
  margin-bottom: 4px;
  color: #757575;
  position: relative;
  order: 0;
}

.selected::after {
  content: "";
  background: url("img/arrow-down.svg");
  background-size: contain;
  background-repeat: no-repeat;
  -webkit-filter: contrast(0);
  filter: contrast(0);
  position: absolute;
  height: 100%;
  width: 32px;
  right: 10px;
  top: 3px;
  transition: all 0.4s;
}

.select-box .options-container {
  max-height: 240px;
  opacity: 1;
  overflow-y: scroll;
  margin-top: 81px;
}

.para-shot {
  white-space: nowrap;
  width: 75%;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* .select-box .options-container + .selected::after {
  transform: rotateX(180deg);
  top: -6px;
} */

.select-box .options-container::-webkit-scrollbar {
  width: 5px;
  background: #ffffff;
  border-radius: 4px;
}

.select-box .options-container::-webkit-scrollbar-thumb {
  background: var(--mildgray);
  border-radius: 4px;
}

.select-box .option, .selected {
  padding: 6px 11px;
  cursor: pointer;
}

.select-box .option:hover {
  background: #eaeaea;
}

.select-box label {
  cursor: pointer;
}

.select-box .option .radio {
  /* display: none; */
}

/* Searchbox */

.search-box input {
  background: #eaeaea;
  width: 100%;
  padding: 7px 16px;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  position: absolute;
  border-radius: 4px 4px 0 0;
  z-index: 100;
  border: 1px solid #cccccc;
  opacity: 0;
  pointer-events: none;
  transition: all 0.4s;
}

.search-box input:focus {
  outline: none;
}

.select-box .options-container~.search-box input {
  opacity: 1;
  pointer-events: auto;
}

/* Select box design End---------------- */

/* @Media Qurey //////////////////// */

@media screen and (max-width: 992px) {
  .left-panel {
    display: none;
  }
  .right-panel {
    width: 100%;
    margin-left: 0px;
    padding: 0 20px;
  }
  .side-drower {
    display: block !important;
    background-color: #15283c !important;
    background-image: url(./img/pattern_h.png) !important;
  }
  .nav-section {
    padding: 0;
  }
  .notifi-box {
    margin-bottom: 10px;
  }
  /* .notifi-box:not(:last-child) {
    margin-right: 0px;
  } */
  .table-search {
    width: 100%;
  }
  .table-search .search-container {
    margin-bottom: 10px;
    float: right;
    width: 100%;
  }
}

@media (min-width: 600px) {
  .MuiTab-root {
    min-width: auto !important;
    padding: 6px 0 !important;
    margin-right: 20px !important;
  }
}
