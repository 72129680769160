$primary: #0602fc;
// $primary: #d2a33b;
$secondary: #709cfb;
$tirtiary: #e0e7f8;
// $secondary: #ffe7b2;
$blue: #1e90ff;
$white: #ffffff;
$lightGray: #f1f1f1;
$darkGray: #6c757d;
$mildGray: #dbd6d6;
$colorGray400: #898989;
$black: #0d0d0d;
$border: 1px solid #4b465c1f;

body {
    background-color: #f5f6fa;
    scroll-behavior: smooth;
    cursor: default;
    font-family: 'Nunito';

}

.family-Courgette {
    font-family: Courgette;
}

.kyc-container {
    border: 1px solid #dcdcdc8a;
    border-radius: 4px;
    background-color: #f5f5f5a6;
    height: 290px;
    overflow: hidden;

    .kyc-header {
        width: 100%;
        height: 65px;
        border-radius: inherit;
        background: linear-gradient(#ffdf9c, #dadada);
        -webkit-clip-path: polygon(0 0, 100% 0, 100% 64%, 50% 100%, 0 64%);
        clip-path: polygon(0 0, 100% 0, 100% 64%, 50% 100%, 0 64%);
    }

    .acc-details {
        margin: 16px 8px 6px 26px;

        .kyc-img-container {
            width: 119px;
            height: 88px;
            background-color: #dcdcdc;
            border-radius: 4px;

            .kyc-img {
                width: inherit;
                height: inherit;
                object-fit: cover;
                border-radius: inherit;
            }
        }
    }

    .kyc-not-found {
        width: 100%;
        height: 223px;
        position: relative;

        img {
            width: inherit;
            height: inherit;
            object-fit: cover;
        }

        .kyc {
            width: inherit;
            height: inherit;

            img {
                width: inherit;
                height: inherit;
                object-fit: cover;
            }
        }

        .kyc:hover {
            img {
                opacity: 0.1;
            }

            .kyc-addBtn {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }
    }
}

.new-outlet-image-container,
.msg-ing-container {
    display: flex;
    justify-content: space-between;

    div {
        // width: 48%;
        margin: 1px;

        label {
            margin: 0;
            color: #868686;
            font-size: 12px;
        }

        .upload-image-container {
            background-color: #f1f1f1;
            width: 97px;
            display: flex;
            height: 97px;
            align-items: center;
            justify-content: center;
            box-shadow: 1px 3px 6px -4px;

            .upload-image {
                object-fit: cover;
                width: 97px;
                height: 97px;
                border-radius: 8px;
            }

            i {
                position: absolute;
                font-size: 26px;
                color: #ababab;
            }
        }
    }
}

.outlet-report-sec {
    background: #ffffff;

    .outlet-Report-card {
        display: grid;
        gap: 15px;
        grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));

        .box-total {
            background-color: white;
            padding: 10px;
            text-transform: capitalize;
            font-weight: 500;
            color: $colorGray400;
            overflow: hidden;
            border-radius: 4px;
            border: $border;

            .total-manu {
                display: flex;
                justify-content: space-between;
                align-items: center;
                font-family: 'Inter';

                .left-icon {
                    margin-right: .5rem;
                }

                .total-cout {
                    font-size: 21px;
                }

                .right-bar {
                    display: flex;
                    align-items: flex-end;
                    margin-right: .2rem;

                    .bar {
                        width: 4px;
                        margin: 2px;
                    }

                    .bar-1 {
                        height: 12px;
                    }

                    .bar-2 {
                        height: 19px;
                    }

                    .bar-3 {
                        height: 15px;
                    }

                    .bar-4 {
                        height: 10px;
                    }

                    .bar-5 {
                        height: 14px;
                    }

                    .bar-6 {
                        height: 8px;
                    }

                    .bar-7 {
                        height: 18px;
                    }
                }
            }
        }
    }

    .outlet-report-sub-tab {
        background: #FFFFFF;

        .outlet-report-list-container {
            display: grid;
            gap: 15px;
            grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));

            .outlet-report-list {
                padding: 13px;
                text-transform: capitalize;
                font-weight: 500;
                font-size: .875rem;
                overflow: hidden;
                border-radius: 6px;
                margin-bottom: 15px;
                border: $border;

                .user-name {
                    color: #7a7a7a;
                    font-size: 17px;
                    margin-bottom: 0;
                }

                .text-teal {
                    color: #009688;
                    margin: 15px 0 5px;
                    display: flex;
                    align-items: center;
                    white-space: nowrap;
                    font-size: .875rem;

                    .tag {
                        color: #009688;
                        background-color: #00968829;
                        padding: 3px 6px;
                        border-radius: 4px;
                    }
                }
            }
        }
    }
}

.category-image-container {
    display: flex;
    justify-content: space-between;

    div {
        // width: 48%;
        margin: 1px;

        label {
            margin: 0;
            color: #868686;
            font-size: 12px;
        }

        .upload-category-image {
            background-color: #f1f1f1;
            width: 36px;
            display: flex;
            height: 36px;
            align-items: center;
            justify-content: center;
            box-shadow: 1px 3px 6px -4px;

            .category-image {
                object-fit: cover;
                width: inherit;
                height: inherit;
                border-radius: 6px;
            }

            i {
                position: absolute;
                font-size: 14px;
            }
        }
    }
}

.addon-image-container {
    background-color: #f1f1f1;
    width: 60px;
    display: flex;
    height: 60px;
    align-items: center;
    justify-content: center;
    box-shadow: 1px 3px 6px -4px;

    .addon-image {
        object-fit: cover;
        width: inherit;
        height: inherit;
        border-radius: 8px;
    }

    i {
        position: absolute;
        font-size: 20px;
    }
}

input[type="file" i] {
    display: none;
}

.image-label {
    color: white;
    pointer-events: auto;
}

.table {
    // background-image: url("https://image.shutterstock.com/image-vector/vector-fast-food-pattern-seamless-260nw-1059759827.jpg");
    font-size: 14px;

    thead tr>th {
        border: none;
        height: 45px;
        font-weight: 600 !important;
    }

    tbody tr>td>div {
        overflow: hidden;
        height: 22px;
    }
}

.table-hover tbody tr:hover {
    color: #212529;
    // background-color: transparent !important;
    background-color: #fbfbfb !important;
}

.form-control:focus {
    background-color: $tirtiary !important;
    border-color: $lightGray;
    box-shadow: none !important;
}

.icon-check-circle {
    color: $mildGray;
    position: absolute;
    right: 23px;
    bottom: 31px;
}

.table-action {
    background-color: $lightGray;
    margin: 2px;
    height: 23px;
    border-radius: 3px;
}

.btn-verify {
    background-color: var(--primary);
    color: var(--white);
    border-radius: 4px !important;
    padding: 3px 21px;
    white-space: nowrap;

    &:hover {
        box-shadow: 0 8px 6px -8px black;
        color: var(--white);
    }
}

.btn-disable {
    background-color: lightgrey;
    color: #000;
    border-radius: 4px !important;
    padding: 3px 21px;
    white-space: nowrap;
}

.btn-update {
    background-color: #c9faeaba;
    color: #000;
    border-radius: 4px !important;
    padding: 3px 21px;
    white-space: nowrap;

    &:hover {
        box-shadow: 0 8px 6px -8px black;
        color: var(--white);
    }
}

.btn-group-outline {
    padding: 3px 21px;
    white-space: nowrap;
    border: 1px solid $secondary;

    &:hover {
        // box-shadow: 0 8px 6px -8px black;
        background-color: $tirtiary;
        color: $primary;
    }

    &:active {
        background-color: $tirtiary;
        color: $primary;
    }

    &:focus {
        background-color: $tirtiary;
        color: $primary;
    }
}

.btn-group-list {
    color: $darkGray;
    padding: 3px 21px;
    white-space: nowrap;
    border: 1px solid $secondary;

    &:hover {
        // box-shadow: 0 8px 6px -8px black;
        background-color: $secondary;
        color: $primary;
    }

    &:active {
        background-color: $secondary;
        color: $primary;
    }

    &:focus {
        background-color: $secondary;
        color: $primary;
    }
}

.btn-cancel {
    background-color: var(--lightgray);
    border-radius: 4px !important;
    padding: 3px 21px;
    margin-left: 10px;
    color: #6c757d;

    &:hover {
        box-shadow: 0 8px 6px -8px black;
        background-color: darkGray;
        color: white;
    }
}

.btn-view-all {
    background-color: transparent;
    border-radius: 4px !important;
    padding: 3px 21px;
    margin-left: 10px;
    color: #fa2f01;

    &:hover {
        box-shadow: 0 8px 6px -8px black;
        background-color: darkGray;
        color: rgb(0, 0, 0);
    }
}

//   action button
.icon-button {
    border: none;
    padding: 6px;
    margin: 3px;
    height: 30px;
    display: flex;
    width: 30px;
    color: #676565;
    border-radius: 4px;
    justify-content: center;
    align-items: center;
}

.icon-button:hover {
    background-color: #02fb93;
}

/* Table search button start-------- */
.table-search {
    .search-container {
        margin-bottom: 10px;
        float: right;

        input[type="search"] {
            margin-top: 8px;
            font-size: 17px;
            background-color: #f7f7f7;
            border: 1px solid #ededed;
            width: 100%;
            border-radius: 4px;
            padding: 4px 12px;
        }
    }
}

/* Table search button End-------- */
table td,
.table th {
    padding: 6px !important;
    /* padding: .75rem; */
    vertical-align: middle !important;
}

.product-info-textarea {
    width: 100%;
    // margin-top: 11px;
}

._2iA8p44d0WZ-WqRBGcAuEV {
    border: none !important;
}

._7ahQImyV4dj0EpcNOjnwA {
    background-color: $primary !important;
}

.resturent-details-container {
    font-size: 14px;

    p {
        margin: 5px 0;
    }

    .mr-15 {
        margin-right: 15px;
    }


    .details-img-container {
        background-color: transparent;
        width: 13rem;
        max-width: 100%;
        min-width: 13rem;
        height: 13rem;
        max-height: 100%;
        min-height: 13rem;
        border-radius: 4px;
        margin-right: 15px;

        img {
            object-fit: cover;
            border-radius: 4px 4px;
            height: inherit;
            width: inherit;
        }
    }

    .resturent-name {
        // text-align: center;
        font-size: 18px;
        text-transform: capitalize;
        font-weight: 500;
        color: #30302f;
        margin: 0px;
    }

    .resturent-address {
        // font-style: italic;
        color: #7e7878;
    }

    .resturent-retings {
        margin-right: 10px;

        .checked {
            color: $primary;
        }
    }

    .resturent-time-box {
        padding: 10px;
        display: inline-block;
        border: $border;
        border-radius: 4px;
        color: $colorGray400;

        .resturent-time-title {
            margin: 0;
            margin-bottom: 4px;
        }

        .resturent-time {
            padding: 2px 9px;
            border-radius: 5px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            overflow: hidden;

            .time-type {
                white-space: nowrap;
            }

            .time {
                white-space: nowrap;
            }
        }
    }
}

.food-details-container {
    background: #f5f6fa;
    border-radius: 13px;
    padding: 12px;
    box-shadow: 0 3px 8px 0 #e6e6e6;

    .discription-box {
        overflow: hidden;
        height: 40px;
    }
}

.food-img-sec {
    width: 70px;
    height: 70px;
    border-radius: 13px;
    margin-right: 1rem;

    .addon-product-table-img {
        width: inherit;
        height: inherit;
        border-radius: inherit;
        object-fit: cover;
    }
}

.form-tab-field {
    display: block;
    width: 100%;
    padding: 3px 7px;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.order-name-container {
    display: flex;
    border: 0.5px solid;
    border-radius: 5px;
    padding: 5px;
    margin-bottom: 2;
    width: auto !important;
    height: 4rem !important;

    span {
        border-right: 0.5px solid;
        padding-right: 2px;
        justify-content: center;
        margin-right: 2px;
    }
}

.maintenance-container {
    background-color: #a30a0a5f;
    box-shadow: 6px 6px 6px #803131;
    color: #000;
    border: none;
    padding: 18px;
    border-radius: 13px;
}

.category-container {
    background-color: #fff;
    box-shadow: 6px 6px 6px #efefef;
    border: none;
    padding: 18px;
    border-radius: 13px;

    .clear-btn {
        border: none;
        background-color: transparent;
        color: red;
        margin-right: 10px;
        padding: 0 3px;
        font-size: 11px;
        border-radius: 57px;
    }

    .clear-btn:hover {
        background-color: var(--primary);
        color: white;
    }

    .category-field {
        margin: 10px;
    }

    // add ons -----
    .normal-field-list {
        background-color: transparent;
        padding: 4px;
        border: none;
    }

    .edit-field-list {
        color: black;
        border: 1px solid #e2eae8;
        padding: 3px;
        border-radius: 5px;
        margin: 0;
    }

    .add-on-list {
        list-style-type: none;

        li {
            display: flex;
            background-color: #f9f9f9;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 3px;
            padding: -5px;
            padding: 4px;

            p {
                margin-bottom: 0;
                padding: 3px 2px;
                width: 83px;
            }
        }
    }
}

.list-group-item {
    padding: 2px;
}

.upload-success {
    background-color: #bafdba;
    padding: 5px;
}

.upload-field {
    background-color: #fdbaba;
    padding: 5px;
}

.slot-list {
    background-color: #fafafa;
    padding: 8px 0 8px 21px;
    margin: 3px 0;
    border: none;
    border-radius: 4px;

    kbd {
        background-color: #d3d6d8;
        color: $black;
    }
}

.dashboard-container {
    background-color: #fff;
    box-shadow: 6px 6px 6px #efefef;
    border: none;
    padding: 13px;
    text-transform: capitalize;
    // border-radius: 13px;
    // height: 200px;
    overflow-y: auto;

    // .dash-table {
    //     font-size: 15px;
    //     width: 100%;
    //     border-collapse: collapse;
    //     td {
    //         vertical-align: baseline !important;
    //     }
    // }
    a {
        // font-size: 11px;
        font-weight: 500;
    }
}

.dash-table {
    font-size: 15px;
    width: 100%;
    border-collapse: collapse;

    td {
        vertical-align: baseline !important;
    }
}

.user-order-container {
    .details {
        padding: 11px;
        border-radius: 10px;
        background: linear-gradient(45deg, whitesmoke, transparent);
        transition: 0.3s;
        transition-timing-function: linear;

        &:hover {
            background-color: #fafafa;
        }
    }
}

// .pagination {
//     justify-content: flex-end;

//     .active {
//         background-color: $primary;
//         color: white;
//     }

//     li {
//         padding: 2px 10px;
//         border: 1px solid $primary;
//         color: $primary;
//     }
// }
.pagination {
    justify-content: flex-end;

    .active {
        background-color: #808b96;
        color: white;
    }

    li {
        //    margin: 1px;
        //    border-radius: 5px;
        cursor: pointer;
        padding: 2px 10px;
        border: 1px solid #d6dbdf;
        color: #808b96;
    }
}

.foods-count {
    .count-chart {
        padding: 20px;
        border-radius: 10px;
        background-color: #fff;
        transition: all 0.3s;
        border: 1px solid #f1f1f1;
        user-select: none;
        // box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

        .media-flex-center {
            display: flex;
            align-items: center;
            width: 100%;

            .count-icon {
                font-size: 22px;
                width: 47px;
                border-radius: 50px;
                height: 47px;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .flex-meta {
                margin-left: 20px;
                line-height: 1.4;
                display: flex;
                flex-direction: column;

                span {
                    &:first-child {
                        font-size: 1.1rem;
                        color: #5c5c5c;
                        font-weight: 600;
                    }

                    &:nth-child(2) {
                        color: var(--light-text);
                        font-size: 0.9rem;
                    }
                }
            }
        }
    }

    svg {
        border-radius: 4px;
        border: 1px solid #f1f1f1;
    }
}

.chart-container {
    background: transparent !important;
    border-radius: 30px;
}

.rotated-text {
    color: red;
    transform: rotate(-90deg);
    font-size: 8px;
    display: inline-flex;
    max-width: 3px;
}

.right-card-panel {
    background-color: #00a537;
    width: 220px;
    height: 220px;
}

.user-details-container {
    background: #fff;
    border-radius: 13px;
    padding: 20px;
    box-shadow: 0 7px 12px 0 rgba(0, 0, 0, 0.04);
}

.msg-box-logo {
    display: flex;
    justify-content: space-between;
    align-items: center;

    img {
        width: 50px;
        height: 50px;
        object-fit: cover;
    }
}

.msg-container {
    textarea {
        width: 100%;
        border: 1px solid #ced4da;
        background-clip: padding-box;
        padding: 10px;
        border-radius: 4px;
    }
}

.msg-ing-container {
    background-color: #f1f1f1;
    width: 94px;
    height: 94px;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 1px 3px 6px -4px;

    .upload-image {
        object-fit: cover;
        width: inherit;
        height: inherit;
        border-radius: inherit;
    }

    i {
        position: absolute;
        font-size: 27px;
    }
}

// subscription ///////////////
.offer-clip {
    background-color: #00a537;
    color: #fff;
    margin: -7px 0 0 -6px;
    padding: 7px 24px 7px 5px;
    -webkit-clip-path: polygon(0 0, 100% 0, 90% 100%, 0 100%);
    clip-path: polygon(0 0, 100% 0, 90% 100%, 0 100%);
}

.subs-box {
    background-color: white;
    margin: 13px 0;
    padding: 6px;
    box-shadow: 0px 2px 8px #d9d9d9;
    border-left: 6px solid #00a537;
    border-top: 1px solid #aaa;
    border-radius: 4px;

    p {
        text-align: center;
        font-size: 15px;
        font-weight: 600;
    }

    .sub-box-footer {
        background-color: #f3f3f3;
        margin: 4px -6px -5px -6px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}

.coupon-container {
    position: relative;
    box-shadow: 2px 2px 9px #aaa;
    border-radius: 4px;
    outline: 1px solid #efefef !important;
    height: 95px;
    white-space: nowrap;
    overflow: hidden;

    .coupon-code {
        padding: 6px;
    }

    .total-users {
        padding: 6px 28px 6px 43px;
        -webkit-clip-path: circle(65% at 65% 50%);
        clip-path: circle(65% at 65% 50%);
        background: #2dacba;
        position: absolute;
        right: 0;
        top: 0;
        height: inherit;
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        h1 {
            margin-bottom: 0;
            font-size: 23px;
        }

        p {
            margin-bottom: 0;
        }
    }

    .coupon-footer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: nowrap;
    }
}

//  collopse----------
.panel-group {
    margin-bottom: 20px;

    .panel {
        margin-bottom: 3px;
        border-radius: 4px;
        background-color: #fff;
        border: 1px solid #ddd;
        border-radius: 4px;
        -webkit-box-shadow: 0 1px 1px #0000000d;
        box-shadow: 0 1px 1px #0000000d;

        .panel-heading {
            color: #333;
            background-color: #f5f5f5;
            border-bottom: 0;
            padding: 10px 15px;
            border-bottom: 1px solid #ddd;
            border-top-left-radius: 3px;
            border-top-right-radius: 3px;

            .panel-title {
                margin-top: 0;
                margin-bottom: 0;
                font-size: 16px;
                color: inherit;
            }
        }
    }
}

// -------Settings Section -----------
.ad-container {
    background-color: white;
    // height: 294px;
    // width: 341px;
    border: 1px solid #ebebeb;

    // margin-right: 10px;
    .extra-ad-sec {
        width: 100%;
        height: inherit;
        overflow: hidden;

        img {
            transition: transform 0.5s;

            &:hover {
                transform: scale(1.1);
            }
        }
    }

    .ad-caption {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100px;
        height: 100px;
        border-radius: 50px;

        .carousel-link {
            display: flex;
            align-items: center;
            justify-content: center;
            width: inherit;
            height: inherit;
            border-radius: inherit;
            color: transparent;
            background-color: transparent;
            transition: all 0.6s ease;

            &:hover {
                // transition: ease-in-out;
                background-color: #0d0d0d99;
                color: white;
            }
        }
    }

    .upload-image-container {
        background-color: #f1f1f1;
        width: 100px;
        display: flex;
        height: 100px;
        align-items: center;
        justify-content: center;
        border: 1px solid #ebebeb;

        .upload-image {
            object-fit: cover;
            width: inherit;
            height: inherit;
            border-radius: 4px;
        }

        i {
            position: absolute;
            font-size: 26px;
            color: #ababab;
        }
    }
}

.setting-img-box {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .card-img {
        border-top-left-radius: calc(0.25rem - 1px);
        border-top-right-radius: calc(0.25rem - 1px);
        height: 120px;
        object-fit: cover;
    }

    .card-foo {
        position: absolute;
        width: 100%;
        height: inherit;

        button {
            display: none;
        }

        &:hover {
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #282828ba;

            button {
                display: flex;
            }
        }
    }
}

.card-not-fund {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #198f28;
    gap: 25px;

    img {
        width: 40%;
        height: auto;
    }
}

.map-icon-container {
    .MuiSvgIcon-root {
        width: 45px !important;
        height: 45px !important;
    }
}

.free-complementary {
    text-decoration-line: line-through !important;
    text-decoration-color: red !important;
    color: grey;
}

// @media Qurey //////
// @media screen and (max-width: 992px) {
// .table-search .search-container {
//     margin-bottom: 10px;
//     float: right;
//     /* width: 100%; */
// }
// }
@media screen and (max-width: 600px) {
    .outlet-report-sec .outlet-report-sub-tab .outlet-report-list-container {
        grid-template-columns: none;
    }
}

.graph-header {
    h3 {
        -webkit-font-smoothing: antialiased;
        font-family: Roboto;
        font-size: 18px;
        color: #757575;
        font-weight: 300;
        margin: 0 0 3px;
    }

    p {
        -webkit-font-smoothing: antialiased;
        font-family: Roboto;
        font-size: 14px;
        color: #BDBDBD;
    }
}

.tab-panel {
    color: #000;
    border: none;
    padding-right: 15px;
    padding-left: 15px;
    cursor: pointer;
    background-color: transparent;

    &:hover {
        background-color: rgba(212, 212, 252, 0.71);
    }

    &.active {
        color: blue;
        border-bottom: 5px solid blue;

    }
}

.panel-btn {
    color: #2dacba;
    border: 1px solid#2dacba;
    background-color: transparent;
    padding-top: 1px;
    padding-bottom: 1px;
    padding-left: 5px;
    padding-right: 5px;

    &.is-active {
        color: #fff !important;
        background-color: #2dacba !important;
    }
}

.no-data {
    color: red;
}